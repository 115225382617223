import React, { ReactNode } from 'react';
import Container from '../components/core/layout/Layout/Container';
import Heading from '../components/core/typography/Heading';
import FaqAccordion from '../components/views/FaqAccordion/FaqAccordion';
import {
  generalFaqCompany,
  generalFaqService,
} from '../page-data/faq/general-faq';
import Button from '../components/core/buttons/Button';
import SEO from '../components/core/meta/SEO';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { FaqQuery } from '../../generated/graphql-types';
import PageHeader from '../components/views/PageHeader/PageHeader';
import {
  getAppRedirectUri,
  HOSTNAME,
} from '../components/utils/getAppRedirectUri';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { renderOptions } from '../contentful/render-options';
import useDatalayer from '../../hooks/use-datalayer';
import { useAnalytics } from '../analytics/AnalyticsProvider';

const FaqPage = () => {
  const [appLocation, setAppLocation] = React.useState<string>(
    '//app.' + HOSTNAME
  );

  React.useEffect(() => {
    setAppLocation(getAppRedirectUri());
  }, []);

  const datalayer = useDatalayer();
  const analytics = useAnalytics();

  const faqData = useStaticQuery<FaqQuery>(graphql`
    query Faq {
      faq: file(relativePath: { eq: "headers/faq.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      acompagnamentoFaq: allContentfulFaq(
        filter: { category: { eq: "FAQ Page - Accompagnamento Section" } }
      ) {
        edges {
          node {
            question {
              question
            }
            answer {
              raw
            }
          }
        }
      }
      servicesFaq: allContentfulFaq(
        filter: { category: { eq: "FAQ Page - Services Section" } }
      ) {
        edges {
          node {
            question {
              question
            }
            answer {
              raw
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO title="Domande frequenti" url="/domande-frequenti" />
      <PageHeader backgroundImage={faqData.faq.childImageSharp.gatsbyImageData}>
        <Container className="py-8">
          <Heading
            variant="h1"
            className="text-white mb-0"
            style={{ color: '#ffffff' }}
          >
            Domande frequenti sul servizio
          </Heading>
        </Container>
      </PageHeader>
      <div className="pb-20 pt-12">
        <section className="py-12">
          <Container>
            <Heading variant="h4" className="mb-0">
              Informazioni generiche su UGO
            </Heading>
            <Heading variant="h5" className="mb-10">
              Dove è attivo il servizio, chi sono gli operatori, quanto costa e
              altre informazioni utili.
            </Heading>
            <FaqAccordion
              faqItems={faqData.acompagnamentoFaq.edges.map(({ node }) => ({
                answer: renderRichText(
                  // @ts-ignore
                  node.answer,
                  renderOptions
                ) as ReactNode,
                question: node.question.question,
              }))}
            />
          </Container>
        </section>
        <section className="py-12">
          <Container>
            <Heading variant="h4" className="mb-0">
              Domande frequenti sulla Piattaforma UGO
            </Heading>
            <Heading variant="h5" className="mb-10">
              Come calcolare un preventivo, come prenotare, come funziona il
              pagmento.
            </Heading>
            <FaqAccordion
              faqItems={faqData.servicesFaq.edges.map(({ node }) => ({
                answer: renderRichText(
                  // @ts-ignore
                  node.answer,
                  renderOptions
                ) as ReactNode,
                question: node.question.question,
              }))}
            />
          </Container>
        </section>
        <section className="flex justify-center">
          <Link to="/#">
            <Button
              variant="filled"
              color="primary"
              onClick={() => {
                analytics.submitEvent<'r-intent-p'>('r-intent-p', {
                  location: 'm-qf-domande_frequenti_button',
                });
              }}
            >
              Calcola un preventivo
            </Button>
          </Link>
        </section>
      </div>
    </>
  );
};

export default FaqPage;
